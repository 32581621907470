<template>
  <div class="view-wrapper" style="height: 100%; overflow: auto">
    <v-toolbar elevation="0"><span class="title">Предложения...</span></v-toolbar>
    <v-container>
      <div class="grid">
        <template v-for="i in 10">
          <v-card :key="i"
              class="mx-auto ma-2 pa-2 elevation-14"
              max-width="350"
          >
            <v-img
                class="white--text align-end"
                height="200px"
                src="https://cms.qz.com/wp-content/uploads/2021/07/2021-06-22T092849Z_1024908675_RC2L5O9WGWBQ_RTRMADP_3_G20-FINANCE-TAX.jpg?quality=75&strip=all&w=1200&h=900&crop=1"
            >
              <v-card-title>Top 10 Australian beaches</v-card-title>
            </v-img>
            <v-card-subtitle class="pb-0">
              Number 10
            </v-card-subtitle>
            <v-card-text class="text--primary">
              <div>Whitehaven Beach</div>
              <div>Whitsunday Island, Whitsunday Islands</div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  color="success"
                  text
              >
                Поделиться
              </v-btn>
              <v-btn
                  color="success"
                  text
              >
                Подробнее
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Test",
  data : () => ({

  })
}
</script>

<style>
.grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: start;
  align-items: center;
}
</style>
